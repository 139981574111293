.home {
  display: flex;
  justify-content: space-between;
}
.left-home {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.the-best-ad > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 8px;
  border-radius: 3rem;
  z-index: 1;
}
.the-best-ad > span {
  z-index: 2;
}
.home-heading {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  text-overflow: inherit;
  color: white;
}
.home-heading > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}
.counter {
  display: flex;
  gap: 2rem;
}
.counter > div {
  display: flex;
  flex-direction: column;
}
.counter > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.counter > div > span:nth-of-type(2) {
  color: var(--gray);
  text-transform: uppercase;
}
.home-button {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.home-button > :nth-child(1) {
  color: white;
  background-color: var(--orange);
  width: 8rem;
}
.home-button > :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid var(--orange);
}
.right-home {
  flex: 1.3;
  position: relative;
  background-color: var(--orange);
}
.right-home > button {
  position: absolute;
  right: 3rem;
  top: 4rem;
  color: black;
}
.heart-rate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  top: 9rem;
}
.heart-rate > :nth-child(2) {
  color: var(--gray);
}
.heart-rate > :nth-child(3) {
  color: white;
  font-size: 1.5rem;
}
.heart-rate > img {
  width: 2rem;
}
.home-img {
  position: absolute;
  top: 12rem;
  right: 18rem;
  width: 23rem;
}
.home-img-back {
  position: absolute;
  top: 1rem;
  right: 26rem;
  z-index: -1;
  width: 25rem;
}
.calories{
    display: flex;
    gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 34rem;
  right: 45rem;
  z-index: -1;
}
.calories>img{
    width: 3rem;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:nth-child(1){
color: var(--gray);
}
.calories>div>:nth-child(2){
color: white;
font-size: 1.5rem;
}
.blur-home{
  width: 22rem;
  height: 30rem;
  left: 0;
}


/* responsive */
@media screen and (max-width:768px){
  .home{
    flex-direction: column;
  }
  .blur-home{
    width: 14rem;
  }
  .the-best-ad{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(.8);
  }
  .home-heading{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .home-heading>div:nth-of-type(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .home-button{
    justify-content: center;
  }
  .counter>div>span:nth-of-type(1){
    font-size: large;
  }
  .counter>div>span:nth-of-type(2){
    font-size: small;
  }
  .right-home{
    position: relative;
    background: none;
  }
  .heart-rate{
    left: 1rem;
    top: 2rem;
  }
  .calories{
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories>div:nth-child(2){
    color: white;
    font-size: 1rem;
  }
  .home-img{
    position: relative;
    width: 15rem;
    left: 7rem;top: 4rem;
    align-self: center;
  }
  .home-img-back{
    width: 15rem;
    left: 2rem;
    top: 0rem;
  }
}