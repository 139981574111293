.join{
    display: flex;
    gap: 20rem;
    padding:  0 2rem;
}
.left-j{
color: white;
font-size: 3rem;
font-weight: bold;
text-transform: uppercase;
position: relative;
}
.left-j>hr{
    position: absolute ;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0 ;
}
.left-j>div{
    display: flex;
    gap: 3rem;

}
.right-j{
    display: flex;
    justify-content: center;
     align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: var(--gray);
    padding: 1rem 2rem ;
      
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.join-btn{
    background-color: var(--orange);
    color: white;
}